<script>
// import {vSlidenIn} from "@/utils/vSlidenIn";
export default {
  directives:{
    // slidenIn:vSlidenIn
  },
  props:{
    imagepath:{
      type:String,
      default:require('@/assets/u19.png')
    },
    title2:{
      type:String,
      default:"Comprehensive system integration and global operational support to create an efficient, secure, and highly collaborative smart oilfield ecosystem"
    },
    title1:{
      type:String,
      default:"Improved Operational Efficiency"
    }
  }


}
</script>

<template>
  <div  class="card">
    <div class="back">
      <div class="imgDesign">
        <img loading="lazy"  style="border-radius: 9px" :src="imagepath">
      </div>
      <div class="titleDesign">
        <div class="title1" style="margin:0 auto 2vh 0" v-html="title1"></div>
        <div class="title2" v-html="title2">
        </div>
      </div>
    </div>


  </div>
</template>

<style scoped lang="scss">
.card{
  //cursor: pointer;
  width: 100%;
  height:100% ;
  padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
  opacity: 1;

  .back{
    background: white;
    border-radius: 9px;
    position: relative;
    box-shadow: 2px 0 12px 0px #c9d9f5;
    height:100% ;
    overflow:hidden;
    .imgDesign{
      width: fit-content;
      height:8vh;
      opacity: 1;
      margin:3vh auto;
      background-position: center;
      background-size:cover;
      background-repeat: no-repeat;
      background-color: transparent;
    }
    .titleDesign{
      padding: 0 1.5vw 2.5248454883vh 1.5vw;
      margin:0 auto;
      color: black;
      width:100%;
      float: right;
      .title1{
        margin-top: 4vh;
        font-size: 1.1709416522vw;
        font-weight: bold;
        text-align: center;
      }
      .title2{
        line-height: 2.8vh;
        font-size: 0.9vw;
      }
    }
  }
}
</style>
