<script >
import {vSlidenIn} from "@/utils/vSlidenIn";
import {vSlidenInstep} from "@/utils/vSlidenInstep";
import {vZoomIn} from "@/utils/vZoomIn";
import {vLeftIn} from "@/utils/vLeftIn";
export default {
  directives:{
    slidenIn: vSlidenIn,
    zoomIn: vZoomIn,
    leftIn:vLeftIn,
    slidenInstep:vSlidenInstep
  },
  props:{
    title1:{
      type:String,
      default:"Smart Oil & Gas Infrastructure Construction"
    },
    title2:{
      type:String,
      default:"Comprehensive system integration and global operational support to create an efficient, secure, and highly collaborative smart oilfield ecosystem"
    }
  },
  methods:{
    clickConsult(){
      this.$emit("clickConsult",true)
    }
  }
}
</script>

<template>
  <div class="screen1">

    <!--      logo  you气通GPT社区 合作伙伴  登录-->
    <!--       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
    <div class="layer2">
      <div  class="title1" style="font-size: unset;line-height: unset"> <img loading="lazy" style="border-radius: 9px" src="@/assets/AnxunLogo.png"></div>
      <div v-sliden-instep="{duration:1200}" class="title1" style="height: unset;line-height: unset;margin-top: 2vh;font-size: 2.4vw;color: #282d7d;font-weight: bolder" v-html="title1"></div>
      <div v-sliden-instep="{duration:1600}"  class="title2" style="font-size: 1.3vw;margin-top: 3vh;font-weight: bolder" v-html="title2"></div>
    </div>
    <div v-sliden-instep="{duration:2000}"  class="title4" style="position: absolute;bottom: 12vh;left:8.1715771231vw;font-weight: bolder" >Oil & Gas Digitalization, Simple and Practical</div>
    <div   style="position: absolute;bottom: 0px;font-weight: bolder"  class="titlle3"   @click="clickConsult('Oil & Gas Generic AI')">
      Product Inquiry
    </div>
    <!--      平台大图-->
    <div class="layer3"></div>
  </div>
</template>

<style scoped lang="scss">
.screen1 {
  position: relative;
  width: 100%;
  height: 74vh;
  padding: 7.8430160692vh 2.860774119vw 2.8430160692vh 5.860774119vw;
  background-image: url("../../../assets/banner.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  /* Add any additional styling as needed */

  .title4{
    height: 8.77626699629172vh;
    font-size: 1.3060658579vw;
    font-family: 'Times New Roman';
    font-weight: 400;
    color: #2f6eff;
    line-height: 9.31520395550062vh;
  }
  .titlle3{
    //margin-top: 2.07911001236094vh;
    margin:6vh;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1vw;
    font-family: 'Times New Roman';
    font-weight: 400;
    color: #ffffff;
    line-height: 3.3374536465vh;
    text-align: center;
    border-radius: 9.1px;
    width: fit-content;
    padding: 2.4624227441vh 1.1989601386vw;
    height: 4.5624227441vh;
    background: #2f6eff;
    border: #2f6eff 1px solid;

  }
  .titlle3:hover{

    color: #2168DB;
    border: #2168DB 1px solid;
    background: rgba(255, 255, 255, 0.5);
  }
  .layer2 {
    position: absolute;
    top: 16.1495673671199vh;
    left: 8.17157712305026vw;


    .title1 {
      width: fit-content;
      height: 5.77626699629172vh;
      font-size: 3.50606585788562vw;
      font-family: 'Times New Roman';
      font-weight: 400;
      color: #2f6eff;
      line-height: 12.23733003708282vh;
    }

    .title2 {
      margin-top: 4.82076637824475vh;
      width: 37vw;
      //text-align: justify;
      height: fit-content;
      font-size:0.9597920277vw;
      font-family: 'Times New Roman';
      font-weight: 400;
      color: black;
      line-height: 3.51520395550062vh;
    }

    .titlle3{
      margin-top: 2.07911001236094vh;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1vw;
      font-family: 'Times New Roman';
      font-weight: 400;
      color: #ffffff;
      line-height: 3.3374536465vh;
      text-align: center;
      border-radius: 9.1px;
      width: fit-content;
      padding: 2.4624227441vh 1.1989601386vw;
      height: 4.5624227441vh;
      background: #2f6eff;
      border: #2f6eff 1px solid;

    }
    .titlle3:hover{

      color: #2168DB;
      border: #2168DB 1px solid;
      background: rgba(255, 255, 255, 0.5);
    }
  }
}
</style>
