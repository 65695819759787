<template>
  <div>
    <div v-if="model===false">
      <div class="totalmakeHole" ref="total">
        <div class="topback"   :style="{ height: '6vh', backgroundColor: scrollback ? 'rgb(245 244 246)' : 'rgb(245 244 246)' ,position:'fixed',top:'0'}">
          <headtip :changeVisable="changeVisable"  @showDialog="showPartner"></headtip>
        </div>
        <div class="makeHole1" @click="changeheadVisable">
          <kefu @toptop="topTop"></kefu>

          <Anxunheader :title1="'Smart Pipeline Network Solutions'" :title2="'Constructing an integrated system that combines `maps, models, data, and business` to achieve digital and intelligent management as well as visualization of pipeline operations'"  @clickConsult="clickConsult('Oil & Gas Generic AI')"></Anxunheader>
<!--          <div class="screenmake0">-->
<!--            <div v-sliden-in="{duration:800}" class="heighLight">Smart Pipeline Network Solutions</div>-->
<!--            <div v-sliden-in="{duration:800}" class="heightLight2" style="width: 155vh">   The Smart Pipeline Network Solutions are based on GIS and BIM technologies, combined with IoT, big data, cloud computing, and artificial intelligence, to provide comprehensive pipeline operation and maintenance management. Centered on "data integration, application fusion, business collaboration, and intelligent management," it builds an integrated system of "map + model + data + business" to <span style="color: rgb(255, 100, 0)">achieve digitalization, online, and intelligent management of the pipeline network. This solution addresses the challenges of poor visibility, high management difficulty, and dispersed data in traditional pipeline networks, enhancing the efficiency and intelligence of pipeline management.</span> </div>-->
<!--          </div>-->
<!--          <div class="screenmake3"  style="background-color: white">-->
<!--            <div v-sliden-in="{duration:800}" class="heighLight" >-->
<!--              Your Benefits-->
<!--            </div>-->
<!--            <div style="display: flex">-->
<!--              <div v-left-in="{duration:800}" class="fanganimg" style=" float: left;    height: 100%;    width: 55%; margin-top: 3%">-->
<!--                <img src="@/assets/anxun21new2.png" style="height: 100%;width: 90%">-->
<!--              </div>-->
<!--              <div v-sliden-in="{duration:800}" style="display: contents">-->
<!--                <div class="grid" style="">-->
<!--                  <div class="card">-->
<!--                    <div class="back">-->
<!--                      <div class="titleDesign">-->
<!--                        <div class="title1">-->
<!--                          Enhanced Visibility and Safety-->
<!--                        </div>-->
<!--                        <div class="title2">-->
<!--                          Real-time digital monitoring and visualization improve pipeline oversight and quick response to emergencies, significantly boosting safety.-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="card">-->
<!--                    <div class="back">-->
<!--                      <div class="titleDesign">-->
<!--                        <div class="title1">Resource Optimization and Cost Reduction-->
<!--                        </div>-->
<!--                        <div class="title2">-->
<!--                          Intelligent scheduling and automation enhance resource efficiency, reducing errors and operational costs.-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="card">-->
<!--                    <div class="back">-->
<!--                      <div class="titleDesign">-->
<!--                        <div class="title1">Improved Decision-making and Efficiency-->
<!--                        </div>-->
<!--                        <div class="title2">-->
<!--                          Data-driven analysis supports informed decision-making, helping to optimize operations and enhance management effectiveness.-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="card">-->
<!--                    <div class="back">-->
<!--                      <div class="titleDesign">-->
<!--                        <div class="title1">Facilitated Smart Transformation-->
<!--                        </div>-->
<!--                        <div class="title2">-->
<!--                          Supports the transition from traditional to smart management, enabling digital, intelligent, and precise pipeline management for future needs.-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->

<!--          </div>-->
          <div class="screenmake111" style="background-color: transparent">
            <div v-sliden-in="{duration:800}" class="heighLight">Your Benefits</div>
            <div class="grid1" style="height: 32vh;">
              <image-up-text-down v-sliden-in="{duration:800 + 400*index}" v-for="(elemnt,index) in yourbenefits" :key="elemnt.title1" :imagepath="require('@/assets'+elemnt.imagepath)" :title1="elemnt.title1" :title2="elemnt.title2"></image-up-text-down>
            </div>
          </div>
          <div class="screenmake21" style="padding-top: 7vh">
            <div v-sliden-in="{duration:800}" class="heighLight"> Business Pain Points and Challenges</div>
<!--            <div class="line"></div>-->
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/technology01.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1"> Insufficient Application of Data Application and Analysis Technology
                    </div>
                    <div class="title2">
                      Pipeline network monitoring technology has gradually been strengthened, but there are still deficiencies in the application of data and analysis technologies.

                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:1200}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/technology02.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Pipe Network Aging and Safety Hazards
                    </div>
                    <div class="title2">
                      The aging of pipeline networks and prominent safety hazards affect normal supply services and may lead to safety accidents.

                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:1600}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/technology03.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Complex Operation and Maintenance Management
                    </div>
                    <div class="title2">
                      The content of operation and maintenance management is complex, with heavy workloads for manual management and record-keeping, resulting in low work efficiency.
                    </div>
                  </div>
                </div>


              </div>

            </div>

          </div>
          <div class="screenmake1" style="background-color: transparent">
            <div v-sliden-in="{duration:800}" class="heighLight">  Solution Architecture</div>
            <div v-sliden-in="{duration:800}" class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
                    <el-tooltip class="item" effect="dark" content="Click to enlarge for a clearer view!" placement="top">
                      <img loading="lazy" @click="showModalTrue(require('@/assets/guanwang11.png'))" style="border-radius: 9px" src="@/assets/guanwang11.png">
                    </el-tooltip>
                  </div>
                </div>


              </div>
            </div>

          </div>
          <div class="screenmake5" style="background-color: white">
            <div v-sliden-in="{duration:800}" class="heighLight">
              Core Features </div>
            <div v-sliden-in="{duration:800}" class="grid">
              <div class="card">
                <div class="back">
                  <div class="titleDesign">
                    <div class="title1">GIS+BIM Integrated Management
                    </div>
                    <div class="title2">
                      <li>Full Lifecycle Management: Covers the entire lifecycle of the pipeline network, from design to maintenance, ensuring data consistency. </li>
                      <li>Visualization and Monitoring: 3D visualization of pipeline layout with real-time monitoring of facility status. </li>
                      <li>Precise Positioning and Monitoring: Supports precise positioning and real-time monitoring of facilities, enabling quick identification of anomalies. </li>
                    </div>
                  </div>
                </div>


              </div>
              <div class="card">
                <div class="back">

                  <div class="titleDesign">
                    <div class="title1">Data Integration and Application Fusion
                    </div>
                    <div class="title2">
                      <li>Multi-source Data Integration: Unifies pipeline network data, eliminates information silos, and promotes data sharing between systems.</li>
                      <li>Big Data Analytics: Deep analysis of real-time and historical data, supporting intelligent scheduling and optimized management.</li>
                      <li>Intelligent Scheduling and Decision-making: Automatically optimizes resource scheduling based on big data, providing multi-dimensional decision support to enhance operational efficiency.</li>
                    </div>
                  </div>
                </div>


              </div>
              <div class="card">
                <div class="back" >
                  <div class="titleDesign">
                    <div class="title1">Digital Operation and Maintenance Management
                    </div>
                    <div class="title2">
                      <li>Operations and Maintenance: Continuous monitoring and preventive maintenance reduce faults and ensure efficient operation.</li>
                      <li>Mobile Inspection:  Supports rapid location and response, improving maintenance efficiency.</li>
                      <li>Cathodic Protection Monitoring: Real-time monitoring of the pipeline network to prevent corrosion, ensuring structural safety, optimizing maintenance, and reducing costs.</li>
                      <li>Fiber Optic Leak Detection: Real-time detection of minor leaks for quick resolution, reducing risks.</li>
                      <li>Drone Inspection: Efficient inspection of complex areas with precise anomaly identification.</li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="screenmake2" style="background-color: transparent">
            <div v-sliden-in="{duration:800}" class="heighLight">   Technical Features</div>
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign" style="width: 12vh">
                    <img loading="lazy"  style="border-radius: 9px" src="@/assets/guanwang1.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Comprehensive and Multi-dimensional Visualization
                    </div>
                    <div class="title2">
                      Real-time visualization from overall layout to detailed sections using GIS and BIM, enhancing pipeline network oversight.
                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:1200}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-radius: 9px" src="@/assets/guanwang2.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Data-driven Intelligent Management
                    </div>
                    <div class="title2">
                      Big data analytics provides intelligent scheduling, fault prediction, and operational optimization.
                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:1600}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-radius: 9px" src="@/assets/guanwang3.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Integrated Multi-terminal Support
                    </div>
                    <div class="title2">
                      Supports large-screen, web, and mobile management for efficient, flexible operations.
                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:2000}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-radius: 9px" src="@/assets/guanwang4.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Efficient and Intelligent Leak Inspection
                    </div>
                    <div class="title2">
                      Combines fiber optic and drone inspections for reliable, safe monitoring and reduced faults.
                    </div>
                  </div>
                </div>


              </div>
            </div>

          </div>

          <div class="screenmake4">
            <div v-sliden-in="{duration:800}" class="heighLight">Successful Cases</div>


            <div class="grid">
              <el-carousel indicator-position="outside" arrow="always" interval="5000">
                <el-carousel-item v-for="item in success" :key="item">
                  <div class="card">
                    <div class="back">
                      <div v-left-in="{duration:800}" class="imgDesign">
                        <el-tooltip class="item" effect="dark" content="Click to enlarge for a clearer view!" placement="top">
                          <img loading="lazy" @click="showModalTrue(item.img)" :src="item.img"/>
                        </el-tooltip>  </div>
                      <div v-sliden-in="{duration:800}" class="titleDesign">
                        <div class="title1">{{item.title1}}</div>
                        <div class="title2" v-html="item.title2" style="text-align: left"></div>
                      </div>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
          <bottomTip></bottomTip>
        </div>
        <div v-if="showModal" @click="closeModal" class="modal-overlay">
          <div class="modal-content">
            <img :src="largeSrc" alt="Large Image" class="large-image">
          </div>
        </div>

        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
    <div v-else>
      <div class="totalmakeHoleModel">
        <div class="makeHole1">
          <div class="screen1">
            <headtip @showDialog="showPartner"></headtip>
            <kefuMobile @topMobile="topTopmobile"></kefuMobile>
            <!--      logo  you气通GPT社区 合作伙伴  登录-->

            <!--       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div v-zoom-in="{duration:800}" class="title1"> <img loading="lazy" style="border-radius: 9px" src="@/assets/AnxunLogo.png"></div>
              <div v-zoom-in="{duration:800}" class="title2" style="font-weight: bolder;font-size:30px;margin-top: 15px;color:black;line-height: 28px">Smart Pipeline Network</div>
              <div v-zoom-in="{duration:800}" class="title2" style="font-weight: bolder;font-size:16px;line-height:26px;margin-top:15px;color:black">Constructing an integrated system that combines "maps, models, data, and business" to achieve digital and intelligent management as well as visualization of pipeline operations</div>
            </div>
            <div class="title4" style="font-weight:bolder">Oil & Gas Digitalization, Simple and Practical</div>

            <div class="titlle3"   @click="clickConsult('Oil & Gas Generic AI')"  style="font-weight:bolder;width: 170px;">
              Product Inquiry
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>
<!--          <div class="screenmake0">-->
<!--            <div v-sliden-in="{duration:800}" class="heighLight"  style="height: fit-content">Smart Pipeline Network Solutions</div>-->
<!--            <div v-sliden-in="{duration:800}" class="heightLight2"> The Smart Pipeline Network Solutions are based on GIS and BIM technologies, combined with IoT, big data, cloud computing, and artificial intelligence, to provide comprehensive pipeline operation and maintenance management. Centered on "data integration, application fusion, business collaboration, and intelligent management," it builds an integrated system of "map + model + data + business" to <span style="color: rgb(255, 100, 0)">achieve digitalization, online, and intelligent management of the pipeline network. This solution addresses the challenges of poor visibility, high management difficulty, and dispersed data in traditional pipeline networks, enhancing the efficiency and intelligence of pipeline management.</span></div>-->

<!--          </div>-->
          <div class="screenmake3">
            <div v-sliden-in="{duration:800}" class="heighLight">Solution Values</div>
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-top-right-radius: 9px;border-top-left-radius: 9px" src="@/assets/anxun21new1.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">
                      Enhanced Visibility and Safety
                    </div>
                    <div class="title2">
                      Real-time digital monitoring and visualization improve pipeline oversight and quick response to emergencies, significantly boosting safety.
                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-top-right-radius: 9px;border-top-left-radius: 9px" src="@/assets/anxun21new2.png">

                  </div>
                  <div class="titleDesign">
                    <div class="title1">Resource Optimization and Cost Reduction
                    </div>
                    <div class="title2">
                      Intelligent scheduling and automation enhance resource efficiency, reducing errors and operational costs.
                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-top-right-radius: 9px;border-top-left-radius: 9px" src="@/assets/anxun21new3.png">

                  </div>
                  <div class="titleDesign">
                    <div class="title1">Improved Decision-making and Efficiency
                    </div>
                    <div class="title2">
                      Data-driven analysis supports informed decision-making, helping to optimize operations and enhance management effectiveness.
                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-top-right-radius: 9px;border-top-left-radius: 9px" src="@/assets/pipeline_111.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Facilitated Smart Transformation
                    </div>
                    <div class="title2">
                      Supports the transition from traditional to smart management, enabling digital, intelligent, and precise pipeline management for future needs.
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="screenmake21">
            <div v-sliden-in="{duration:800}" class="heighLight"> Business Pain Points and Challenges</div>
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card" style="margin-top: 10px">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/technology01.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1"> Insufficient Application of Data Application and Analysis Technology
                    </div>
                    <div class="title2">
                      Pipeline network monitoring technology has gradually been strengthened, but there are still deficiencies in the application of data and analysis technologies.

                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/technology02.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Pipe Network Aging and Safety Hazards
                    </div>
                    <div class="title2">
                      The aging of pipeline networks and prominent safety hazards affect normal supply services and may lead to safety accidents.

                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/technology03.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Complex Operation and Maintenance Management
                    </div>
                    <div class="title2">
                      The content of operation and maintenance management is complex, with heavy workloads for manual management and record-keeping, resulting in low work efficiency.
                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/technology04.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Inadequate Decision Support
                    </div>
                    <div class="title2">
                      Due to insufficient data integration, lack analytical tools or analysis depth, it is impossible to provide adequate decision support for management.

                    </div>
                  </div>
                </div>


              </div>
            </div>


          </div>
          <div class="screenmake1">
            <div v-sliden-in="{duration:800}" class="heighLight">  Solution Architecture</div>
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-radius: 9px" src="@/assets/guanwang11.png">
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="screenmake6">
            <div v-sliden-in="{duration:800}" class="heighLight" style="margin-top:15px">Core Features </div>
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back" style="height: 40vh">
                  <div class="titleDesign">
                    <div class="title1">
                      GIS+BIM Integrated Management
                    </div>
                    <div class="title2">
                      <li><span style="font-weight: bold">Full Lifecycle Management: </span> Covers the entire lifecycle of the pipeline network, from design to maintenance, ensuring data consistency.</li>
                      <li><span style="font-weight: bold">Visualization and Monitoring: </span> 3D visualization of pipeline layout with real-time monitoring of facility status.</li>
                      <li><span style="font-weight: bold">Precise Positioning and Monitoring: </span> Supports precise positioning and real-time monitoring of facilities, enabling quick identification of anomalies.</li>

                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back" style="height: 44vh">
                  <div class="titleDesign">
                    <div class="title1">
                      Data Integration and Application Fusion
                    </div>
                    <div class="title2">
                      <li><span style="font-weight: bold">Multi-source Data Integration:</span> Unifies pipeline network data, eliminates information silos, and promotes data sharing between systems.</li>
                      <li><span style="font-weight: bold">Big Data Analytics:</span> Deep analysis of real-time and historical data, supporting intelligent scheduling and optimized management.</li>
                      <li><span style="font-weight: bold">Intelligent Scheduling and Decision-making:</span> Automatically optimizes resource scheduling based on big data, providing multi-dimensional decision support to enhance operational efficiency.</li>
                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="titleDesign">
                    <div class="title1">
                      Digital Operation and Maintenance Management
                    </div>
                    <div class="title2">
                      <li><span style="font-weight: bold">Operations and Maintenance:</span> Continuous monitoring and preventive maintenance reduce faults and ensure efficient operation.</li>
                      <li><span style="font-weight: bold">Mobile Inspection:</span> Supports rapid location and response, improving maintenance efficiency.</li>
                      <li><span style="font-weight: bold">Cathodic Protection Monitoring: </span>Real-time monitoring of the pipeline network to prevent corrosion, ensuring structural safety, optimizing maintenance, and reducing costs.</li>
                      <li><span style="font-weight: bold">Fiber Optic Leak Detection:</span> Real-time detection of minor leaks for quick resolution, reducing risks.</li>
                      <li><span style="font-weight: bold">Drone Inspection:</span> Efficient inspection of complex areas with precise anomaly identification.</li>

                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
          <div class="screenmake2">
            <div v-sliden-in="{duration:800}" class="heighLight">   Technical Features</div>
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign" style="width: 8vh;">
                    <img loading="lazy"  style="border-radius: 9px" src="@/assets/guanwang1.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Comprehensive and Multi-dimensional Visualization
                    </div>
                    <div class="title2">
                      Real-time visualization from overall layout to detailed sections using GIS and BIM, enhancing pipeline network oversight.
                    </div>
                  </div>
                </div>
              </div>

              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-radius: 9px" src="@/assets/guanwang2.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Data-driven Intelligent Management
                    </div>
                    <div class="title2">
                      Big data analytics provides intelligent scheduling, fault prediction, and operational optimization.
                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back"  >
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-radius: 9px;height: 40px;margin-top: 2.5px" src="@/assets/guanwang3.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Integrated Multi-terminal Support
                    </div>
                    <div class="title2">
                      Supports large-screen, web, and mobile management for efficient, flexible operations.
                    </div>
                  </div>
                </div>
              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back" >
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-radius: 9px" src="@/assets/guanwang4.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Efficient and Intelligent Leak Inspection
                    </div>
                    <div class="title2">
                      Combines fiber optic and drone inspections for reliable, safe monitoring and reduced faults.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="screenmake4">
            <div v-sliden-in="{duration:800}" class="heighLight">Successful Cases</div>
            <div class="grid">
              <el-carousel indicator-position="outside" arrow="always" interval="5000">
                <el-carousel-item v-for="item in success" :key="item">
                  <div class="card">
                    <div class="back">
                      <div v-zoom-in="{duration:800}" class="imgDesign">
                        <img loading="lazy" :src="item.img"/>
                      </div>
                      <div v-zoom-in="{duration:800}" class="titleDesign">
                        <div class="title1">{{item.title1}}</div>
                        <div class="title2" v-html="item.title2"></div>
                      </div>
                    </div>


                  </div>


                </el-carousel-item>
              </el-carousel>
            </div>

          </div>

          <div ref="bottom">
            <bottomTip></bottomTip>
          </div>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
  </div>


</template>


<script>

import headtip from "@/views/headTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import {shareUrl} from "@/utils/vxshare";
import kefu from "@/views/kefu.vue";
import kefuMobile from "@/views/kefuMobile.vue";
import {vSlidenIn} from "@/utils/vSlidenIn.js";
import {vZoomIn} from "@/utils/vZoomIn.js";
import {vLeftIn} from "@/utils/vLeftIn.js";
import {vSlidenInstep} from "@/utils/vSlidenInstep";
import Anxunheader from "@/views/Anxun/component/Anxunheader.vue";
import ImageUpTextDown from "@/views/component/imageUpTextDown.vue";
export default {
  directives: {
    slidenIn: vSlidenIn,
    zoomIn: vZoomIn,
    leftIn:vLeftIn,
    slidenInstep:vSlidenInstep
  },
  name: "",

  props: [],

  components: {ImageUpTextDown, Anxunheader, PartnerTip, consultTip, headtip,bottomTip,kefu,kefuMobile},

  data() {

    return {
      yourbenefits:[
          {
        imagepath:'/storagebenefit2.png',
        title1:'Enhanced Visibility and Safety',
        title2:'Real-time digital monitoring and visualization improve pipeline oversight and quick response to emergencies, significantly boosting safety.'
      },{
        imagepath:'/stationbenefit2.png',
        title1:'Resource Optimization and Cost Reduction',
        title2:'Intelligent scheduling and automation enhance resource efficiency, reducing errors and operational costs.'
      },{
        imagepath:'/stationbenefit3.png',
        title1:'Improved Decision-making and Efficiency',
        title2:'Data-driven analysis supports informed decision-making, helping to optimize operations and enhance management effectiveness.'
      },{
        imagepath:'/stationbenefit4.png',
        title1:'Facilitated Smart Transformation',
        title2:'Supports the transition from traditional to smart management, enabling digital, intelligent, and precise pipeline management for future needs.'
      }
      ],
      changeVisable:1,

      showModal: false,
      largeSrc:'',
      title:'Oil & Gas Generic AI',
      pageTitle: 'Smart Pipe Network Solution_Anvision-Oil & Gas Communication GPT',
      pageDescription: 'Anvision Digital Smart Pipe Network Solution builds digital infrastructure: including automatic control systems, big data platforms and online monitoring centers to achieve automated control, data analysis and real-time monitoring. ',
      pageKeywords: 'Intelligent pipeline network, intelligent pipeline inspection management system, Anvision, Oil & Gas communication GPT',
      model:false,   scrollback:false,
      dialogconsult:false,
      dialogVisible:false,
      success:[{
        img:require("@/assets/guanwang23.png"),
        title1:"Smart Pipeline Network",
        title2:"<li>Inspection Project for The Export Pipeline Network of A Domestic Pressurized Hydrocarbon Removal Station</li>",
      },
      ]
    }

  },

  methods: {
    topTopmobile() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    topTop() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      const element = this.$refs.total;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },

    showModalTrue(item){
      this.showModal = true;
      console.log(item)
      this.largeSrc=item
    },
    closeModal() {
      this.showModal = false;
    },

    clickConsult(item){

      console.log(item)
      this.title=item
      this.dialogVisible = true;
      sessionStorage.setItem('title',item)
    },
    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
    hide(){
      this.dialogconsult=false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    showPartner(){
      this.dialogconsult=true;
    },
    changeheadVisable(){
      if(this.changeVisable===1){
        this.changeVisable=0
      }
      else{
        this.changeVisable=1
      }
      console.log("父组件", this.changeVisable)
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    handleScroll() {
      if (this.model === false) {
        this.scrollHeight = this.$refs.total.scrollTop;
      } else {
        this.scrollHeight = this.$refs.totalModel.scrollTop;
      }

      if (this.scrollHeight < 100) {
        this.scrollTrue = true
      } else {
        this.scrollTrue = false
      }
      if (this.scrollHeight < 50) {
        this.scrollback = false
      } else {
        this.scrollback = true
      }
      console.log("gaodu", this.scrollHeight < 100)
    },
  },
  //进入该页面时，用之前保存的滚动位置赋值
  beforeDestroy() {
    // 移除事件监听器
    this.$refs.total.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.$refs.total.addEventListener('scroll', this.handleScroll);

    document.title=this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);

    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }

    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  created() {
    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: 'Smart Pipeline Network Solutions_Anvision-Oil & Gas Communication GPT',
        pageDescription: 'Anvision Digital Smart Pipe Network Solution builds digital infrastructure: including automatic control systems, big data platforms and online monitoring centers to achieve automated control, data analysis and real-time monitoring. ',
        pageKeywords: 'Intelligent pipeline network, intelligent pipeline inspection management system, Anvision, Oil & Gas communication GPT',

      };
      shareUrl(shareData);
    }, 1000);
  },

}

</script>


<style type="text/scss" lang="scss" scoped>
.totalmakeHole{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .topback{
    position: fixed;
    z-index:999999999999;
    width: 100vw;
    padding: 0 0 0 2.86077411900635vw;
  }
  .makeHole1 {
    //z-index:: 3;
    position: relative;
    //width: 100%;
    //height: 100vh;
    //.screen1 {
    //  //z-index:: 3;
    //  position: relative;
    //  width: 100%;
    //  height: 63vh;
    //  padding: 7.8430160692vh 2.860774119vw 2.8430160692vh 5.860774119vw;
    //  background-image: url("../../assets/banner.png");
    //  background-position: center;
    //  background-size: cover;
    //  background-repeat: no-repeat;
    //  background-color: transparent;
    //  /* Add any additional styling as needed */
    //  .titlle3{
    //    //margin-top: 2.07911001236094vh;
    //    margin:6vh;
    //    cursor: pointer;
    //    display: flex;
    //    justify-content: center;
    //    align-items: center;
    //    font-size: 1vw;
    //    font-family: 'Times New Roman';
    //    font-weight: 400;
    //    color: #ffffff;
    //    line-height: 3.3374536465vh;
    //    text-align: center;
    //    border-radius: 9.1px;
    //    width: fit-content;
    //    padding: 2.4624227441vh 1.1989601386vw;
    //    height: 4.5624227441vh;
    //    background: #2f6eff;
    //    border: #2f6eff 1px solid;
    //
    //  }
    //  .titlle3:hover{
    //
    //    color: #2168DB;
    //    border: #2168DB 1px solid;
    //    background: rgba(255, 255, 255, 0.5);
    //  }
    //  .layer2 {
    //    position: absolute;
    //    top: 16.1495673671199vh;
    //    left: 8.17157712305026vw;
    //
    //    .title1 {
    //      width: fit-content;
    //      height: 5.77626699629172vh;
    //      font-size: 3.50606585788562vw;
    //      font-family: 'Times New Roman';
    //      font-weight: 400;
    //      color: #2f6eff;
    //      line-height: 12.23733003708282vh;
    //    }
    //
    //    .title2 {
    //      margin-top: 4.82076637824475vh;
    //      width: 37vw;
    //      //text-align: justify;
    //      height: fit-content;
    //      font-size:0.9597920277vw;
    //      font-family: 'Times New Roman';
    //      font-weight: 400;
    //      color: black;
    //      line-height: 3.51520395550062vh;
    //    }
    //    .title4{
    //      height: 8.77626699629172vh;
    //      font-size: 1.3060658579vw;
    //      font-family: 'Times New Roman';
    //      font-weight: 400;
    //      color: #2f6eff;
    //      line-height: 9.31520395550062vh;
    //    }
    //    .titlle3{
    //      margin-top: 2.07911001236094vh;
    //      //cursor: pointer;
    //      display: flex;
    //      justify-content: center;
    //      align-items: center;
    //      font-size: 1vw;
    //      font-family: 'Times New Roman';
    //      font-weight: 400;
    //      color: #ffffff;
    //      line-height: 3.3374536465vh;
    //      text-align: center;
    //      border-radius: 9.1px;
    //      width: fit-content;
    //      padding: 2.4624227441vh 1.1989601386vw;
    //      height: 4.5624227441vh;
    //      background: #2f6eff;
    //      border: #2f6eff 1px solid;
    //
    //    }
    //    .titlle3:hover{
    //
    //      color: #2168DB;
    //      border: #2168DB 1px solid;
    //      background: rgba(255, 255, 255, 0.5);
    //    }
    //  }
    //
    //  //.layer3 {
    //  //  position: absolute;
    //  //  top: 5.06798516687268vh;
    //  //  right: 11.61178509532062vw;
    //  //  width: 47.42923165800116vw;
    //  //  height: 72.80593325092707vh;
    //  //  background-image: url("../assets/GPTplant.png");
    //  //  background-size: 100% auto;
    //  //  background-position: center;
    //  //  background-repeat: no-repeat;
    //  //  background-color: transparent;
    //  //  opacity: 1;
    //  //}
    //}
    .screenmake0{
      padding: 7.78739184177998vh 8.09763142692085vw 0 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:8.23733003708282vh;
        opacity: 1;
        font-size: 1.7vw;
        //color: #53A3F7;
        font-weight: 600;
        font-family: 'Times New Roman';
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: left;
        height: fit-content;
        font-weight: bold;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 80vw;
        font-size: 1.0287117273vw;
        font-family: 'Times New Roman';
      }

    }
    .screenmake21 {
      padding: 7.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;
      position: relative;
      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:6.23733003708282vh;
        opacity: 1;
        font-size: 1.7vw;
        font-weight: 600;
        //color: #53A3F7;
        font-family: 'Times New Roman';
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: left;
        height: fit-content;
        font-weight: bold;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 80vw;
        font-size: 1.15vw;
        font-family: 'Times New Roman';
      }
      .line{
        height: 1vh;
        width: 82%;
        left:50%;
        transform: translateX(-50%);
        border-bottom: dashed black 1px;
        position: absolute;
        top:11vh;
        z-index: 1;

      }

      .grid {
        z-index: 2;
        margin-top: 0vh;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 1vw;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            //background: #EFF5FF;
            border-radius: 9px;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height: fit-content;
            overflow: hidden;

            .imgDesign {
              width: 8vh;
              height:8vh;
              margin:0 auto 2vh auto;
              opacity: 1;
            }

            .titleDesign {
              padding: 0;
              margin: 0 auto;
              color: black;
              float: right;

              .title1 {
                margin-top: 0;
                text-align: center;
                font-size: 1.1709416522vw;
                font-weight: bold;
                height: 5vh;
                display: flex;
                justify-content: center;
                align-items: center;
              }

              .title2 {
                margin-top: 2vh;
                text-align: left;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
              }

              .title3 {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake1{
      padding: 7vh 8.09763142692085vw 7.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:8.23733003708282vh;
        opacity: 1;
        font-size: 1.7vw;
        //color: #53A3F7;
        font-weight: 600;
        font-family: 'Times New Roman';
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: 'Times New Roman';
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            //background: #EFF5FF;
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height:fit-content ;
            overflow:hidden;
            .imgDesign{
              width: 100%;
              height:fit-content;
              opacity: 1;
            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake5{
      padding: 7.78739184177998vh 8.09763142692085vw 4.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: transparent;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:8.23733003708282vh;
        opacity: 1;
        font-size: 1.7vw;
        //color: #53A3F7;
        font-weight: 600;
        font-family: 'Times New Roman';
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: 'Times New Roman';
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        column-gap: 0;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            background: #ebf1fd;
            border-radius: 9px;
            //display: flex;
            position: relative;
            border: 1px solid white;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            height: 61vh;
            padding: 2vh 1.5vw 3vh 1.5vw;
            overflow:hidden;
            .imgDesign{
              width: 5vh;
              margin:0 auto 1vh auto;
              height:5vh;
              opacity: 1;
            }
            .titleDesign{
              padding: 0;
              margin:0 0 0 1.5vw;
              color: black;
              width: 100%;
              float: right;
              margin-top: 20px;
              .title1{
                margin-top: 0;
                font-size: 1vw;
                display: flex;
                //justify-content: center;
                align-items: center;
                height: 2vw;
                font-weight: bold;
                justify-content: center
              }
              .title2{
                margin-top: 2vh;
                //text-align: justify;
                line-height: 3vh;
                font-size: 0.8243212016vw;
              }
              .title3{
                display: grid;
                grid-template-columns: minmax(0,0.5fr) minmax(0,1fr);
                margin-top: 2vh;
                line-height: 3vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake2{
      padding: 7.78739184177998vh 8.09763142692085vw 4.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      //background: transparent;
      background: white;
      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:8.23733003708282vh;
        opacity: 1;
        font-size: 1.7vw;
        //color: #53A3F7;
        font-weight: 600;
        font-family: 'Times New Roman';
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: 'Times New Roman';
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        column-gap: 2vw;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            background: white;
            //display: flex;
            position: relative;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            height: 39vh;
            overflow:hidden;
            .imgDesign{
              width: 8vh;
              height: 8vh;
              opacity: 1;
              margin: 3vh auto;
              //background-image: url("../../assets/menage6.png");
            }
            .titleDesign{
              padding: 0;
              margin:0 0 0 1.5vw;
              color: black;
              width: 85%;
              //float: right;
              .title1{
                margin-top: 0;
                font-size: 1vw;
                display: flex;
                //justify-content: center;
                align-items: center;
                text-align: center;
                justify-content: center;
                height: 2vw;
                font-weight: bold;
              }
              .title2{
                margin-top: 2vh;
                //text-align: justify;
                line-height: 3vh;
                font-size: 0.8243212016vw;
              }
              .title3{
                display: grid;
                grid-template-columns: minmax(0,0.5fr) minmax(0,1fr);
                margin-top: 2vh;
                line-height: 3vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake111{
      padding: 7.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;
      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:6.23733003708282vh;
        opacity: 1;
        font-size: 1.7vw;
        font-weight: 600;
        //color: #53A3F7;
        font-family: 'Times New Roman';
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: left;
        height: fit-content;
        font-weight: bold;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 80vw;
        font-size: 1.15vw;
        font-family: 'Times New Roman';
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            background: #eeeeef;
            border-radius: 9px;
            display: flex;
            position: relative;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            height:95.87144622991347vh ;
            overflow:hidden;
            .imgDesign{
              width: fit-content;
              height:100%;

              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 1.5vw 0 1.5vw;
              margin:auto 1vw auto auto;
              color: black;
              width:60%;
              text-align: center;
              float: right;
              .title1{
                margin-top: 2vh;
                font-size: 1vw;
                font-weight: bold;
                text-align: center;
              }
              .title2{
                line-height: 2.8vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
      .grid1{
        margin-top:2.78739184177998vh ;
        position: relative;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        //.card{
        //  //cursor: pointer;
        //  width: 100%;
        //  height:fit-content ;
        //  padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
        //  opacity: 1;
        //
        //  .back{
        //    background: white;
        //    border-radius: 9px;
        //    position: relative;
        //    box-shadow: 2px 0 12px 0px #c9d9f5;
        //    height:36vh ;
        //    overflow:hidden;
        //    .imgDesign{
        //      width: fit-content;
        //      height:8vh;
        //      opacity: 1;
        //      margin:3vh auto;
        //      background-position: center;
        //      background-size:cover;
        //      background-repeat: no-repeat;
        //      background-color: transparent;
        //    }
        //    .titleDesign{
        //      padding: 0 1.5vw 2.5248454883vh 1.5vw;
        //      margin:0 auto;
        //      color: black;
        //      width:100%;
        //      float: right;
        //      .title1{
        //        margin-top: 4vh;
        //        font-size: 1.1709416522vw;
        //        font-weight: bold;
        //        text-align: center;
        //      }
        //      .title2{
        //        line-height: 2.8vh;
        //        font-size: 0.9vw;
        //      }
        //
        //    }
        //
        //  }
        //}

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake3{
      padding: 7.78739184177998vh 5.09763142692085vw;
      width: 100%;
      height: fit-content;
      //height: 50vh;
      //height: fit-content;
      background: transparent;
      //background-image: url(@/assets/guanwang100.png);
      //background-image: url("../../assets/guanwang100.png");
      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:8.23733003708282vh;
        opacity: 1;
        font-size: 1.7vw;
        //color: #53A3F7;
        font-weight: 600;
        font-family: 'Times New Roman';
        line-height: 4.23733003708282vh;

      }
      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: 'Times New Roman';
      }
      .grid{
        margin-top: 3vh;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0,1fr) ;
        grid-template-rows: 13vh 13vh 13vh 13vh;;
        column-gap: 2vw;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            background: rgba(0,0,0,0);
            border-radius: 9px;
            position: relative;
            height:fit-content ;
            overflow:hidden;
            .imgDesign{
              width: 100%;
              height:fit-content;
              opacity: 1;
            }
            .titleDesign{
              padding: 0;
              margin:3vh auto;
              color: black;
              height: 20vh;
              width: 100%;
              float: right;
              .title1{
                margin-top: 0;
                font-size: 1vw;
                display: flex;
                //justify-content: center;
                align-items: center;
                height: 2vw;
                font-weight: bold;
              }
              .title2{
                margin-top: 1vh;
                //text-align: justify;
                line-height: 3vh;
                font-size: 0.8243212016vw;
              }
              .title3{
                display: grid;
                grid-template-columns: minmax(0,0.5fr) minmax(0,1fr);
                margin-top: 2vh;
                line-height: 3vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake4{
      padding: 7.78739184177998vh 5.09763142692085vw;
      width: 100%;
      height:fit-content;
      background: #212a3c;

      .heighLight{
        display: flex;
        justify-content: center;
        text-align: center;
        height:8.23733003708282vh;
        font-size: 1.7vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        color:white;
        line-height: 4.23733003708282vh;

      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .card{
          //cursor: pointer;
          width: 86%;
          margin:0 auto;
          height:fit-content ;
          padding:0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;
          .back{
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height:48vh ;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              width: fit-content;
              height:100%;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 1.5248454883vh 0.5vw 1.5248454883vh 1vw;
              margin:auto 1vw auto 1vw;
              width: 40%;
              color: white;
              float: right;
              .title1{
                font-size: 1vw;
              }
              .title2{
                //word-break: break-all;
                margin-top: 3vh;
                line-height: 3vh;
                font-size: 0.8243212016vw;
                ::v-deep li{
                  margin-top:5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height:50vh !important;

        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0  ;
          //cursor: pointer;

          border-radius: 50%;
          width: 55px;
          height: 55px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight:800;
          font-size: 25px;
        }
      }
    }
  }
  .modal-overlay {
    z-index: 999999999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  }

  .large-image {
    max-width: 100%;
    max-height: 80vh;
  }

}
.totalmakeHoleModel{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    //z-index:: 3;
    position: relative;
    width: 100%;
    height: 100vh;
    .screen1 {
      //z-index:: 3;
      position: relative;
      width: 100%;
      height: 670px;
      padding: 2.84301606922126vh 2.86077411900635vw  2.84301606922126vh 7.86077411900635vw;
      background-image: url("../../assets/bannerModel.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

      .layer2 {
        position: absolute;
        top: 80px;
        left: 9.17157712305026vw;

        .title1 {
          width:120px;
          font-size: 3.50606585788562vw;
          font-family: 'Times New Roman';
          font-weight: 400;
          color: #2f6eff;
        }

        .title2 {
          margin-top: 10px;
          width: 95%;
          height: fit-content;
          font-size: 14px;
          font-family: 'Times New Roman';
          font-weight: 400;
          color: #4B5A8B;
          line-height: 20px;
        }



      }
      .title4{
        position: absolute;
        bottom: 100px;
        left: 50%;
        transform: translateX(-50%);
        height: fit-content;
        font-weight: 400;
        width: 100%;
        font-size: 16px;
        color: #2f6dfd;
        line-height: 20px;
        text-align: center; /* 用于内部文本的水平居中 */
      }
      .titlle3{
        position: absolute;
        bottom:30px;
        left:50%;
        transform: translateX(-60%);
        font-size: 14px;
        font-family: 'Times New Roman';
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 9.1px;
        width: fit-content;
        margin: 0 15px;
        padding: 5px 4.6875vw;
        height: 35px;
        color:white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;

      }
      //.layer3 {
      //  position: absolute;
      //  top: 5.06798516687268vh;
      //  right: 11.61178509532062vw;
      //  width: 47.42923165800116vw;
      //  height: 72.80593325092707vh;
      //  background-image: url("../assets/GPTplant.png");
      //  background-size: 100% auto;
      //  background-position: center;
      //  background-repeat: no-repeat;
      //  background-color: transparent;
      //  opacity: 1;
      //}
    }
    .screenmake0{
      //z-index:: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw 0 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: 'Times New Roman';
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        padding:19px 2vw;
        text-align: left;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: 'Times New Roman';
        font-weight: bold;
        color: #2C3E50;
        line-height: 25px;
      }

    }
    .screenmake21{
      width: 100vw;
      height: fit-content;
      margin-top: -15px;
      padding:20px   4.6875vw 20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: 'Times New Roman';
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        position: relative;
        height: fit-content;
        display: grid;
        margin-top: 10px;
        grid-template-columns: minmax(0,1fr) ;
        column-gap: 5px;
        .card{
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          display: flex;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .title1{
            margin: 5px auto;
            text-align: left;
            font-size: 16px;
            font-weight: bold;
          }
          .title2{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .title3{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .titleDesign{
            width: 90%;
            margin: 0 0 0 15px;
          }
          .back{
            background: white;
            border-radius: 9px;
            column-gap: 5px;
            position: relative;
            padding: 0 10px 0 10px;
            height:fit-content;
            display: flex;
            overflow:hidden;
            .imgDesign{
              width: 45px;
              height:45px;
              margin:0 auto 15px auto;
              opacity: 1;
            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake1{
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw 20px 4.6875vw;
      background: transparent;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: 'Times New Roman';
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        position: relative;
        height: fit-content;
        .card{
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .titleDesign{
            margin-top: 20px;
          }
          .back{
            background: white;
            border-radius: 9px;
            column-gap: 5px;
            position: relative;
            height:fit-content ;
            margin-top: 15px;
            overflow:hidden;
            .imgDesign{
              width: 100%;
              //border: #666666 1px solid;
              height:fit-content;
              opacity: 1;

            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake2{
      //z-index:: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background: transparent;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: 'Times New Roman';
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        position: relative;
        height: fit-content;
        display: grid;
        margin-top: 10px;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr);
        column-gap: 5px;
        .card{
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .title1{
            margin: 5px auto;
            text-align: center;
            font-size: 14px;
            font-weight: bold;
          }
          .title2{
            margin-top: 5px;
            text-align: left;
            line-height: 22px;
            font-size: 13px;
          }
          .title3{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .titleDesign{
            margin-top: 10px;
          }
          .back{
            background: white;
            border-radius: 9px;
            column-gap: 5px;
            position: relative;
            padding: 15px 10px ;
            height:270px;
            margin-top: 5px;
            overflow:hidden;
            .imgDesign{
              width: 45px;
              height:45px;
              margin:0 auto 15px auto;
              opacity: 1;
            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake3{
      //z-index:: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: 'Times New Roman';
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        position: relative;
        height: fit-content;
        margin-top: 10px;
        .card{
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .title1{
            margin: 5px auto;
            text-align: center;
            font-size: 16px;
            font-weight: bold;
          }
          .title2{
            margin: 15px auto;
            width: 45vh;
            line-height: 22px;
            font-size: 14px;
          }

          .titleDesign{

            margin:10px 10px;
          }
          .back{
            margin-top: 10px;
            background-color: #ebf1fd;
            padding: 0 0  10px 0;
            border-radius: 9px;
            column-gap: 5px;
            position: relative;
            height:fit-content ;
            overflow:hidden;
            .imgDesign{
              width: 100%;
              height:100%;
              opacity: 1;

            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake4{
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;

      padding:20px 4.6875vw;
      background: black;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        color: white;
        font-family: 'Times New Roman';
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .arrowButtonleft {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          left:-55px;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }
        .arrowButtonright {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          right: -55px;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }
        .card{
          cursor: pointer;
          width: 98%;
          margin:0 auto;
          height:fit-content ;
          padding:0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;
          .back{
            border-radius: 9px;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height:fit-content;
            overflow:hidden;
            background: #19202e;
            .imgDesign{
              width: 100%;
              height:194px;
              opacity: 1;

            }
            .titleDesign{
              padding: 15px 20px 25px 20px;
              color: white;
              width:100%;
              float: right;
              .title1{
                margin-top:15px;
                font-size: 16px;
              }
              .title2{
                margin-top: 15px;
                line-height: 22px;
                font-size:14px;
                ::v-deep li{
                  margin-top:5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 340px !important;

        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__arrow {
          display: block;
          //border: 1px solid white;
          outline: -5px;
          padding: 0;
          margin: -65px -10px  ;
          cursor: pointer;

          border-radius: 0;
          width: 25px;
          height: 48px;
          background: rgba(245, 246, 250, 0.8);
          color: #131313;
          position: absolute;
          top: 50%;
          //z-index:: 100;
          transform: translateY(-50%);
          text-align: center;
          font-size: 14px;
        }
      }
    }
    .screenmake6{
      //z-index:: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:21px;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: 'Times New Roman';
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #2C3E50;
        line-height: 25px;
      }

      .grid{
        position: relative;
        height: fit-content;
        .card{
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .title1{
            margin-top: 5px;
            font-size: 16px;
            font-weight: bold;
            text-align: center;
          }
          .title2{

            width: auto;
            margin: 2vh;
            line-height: 22px;
            font-size: 14px;
          }
          .titleDesign{
            margin-top: 20px;
          }
          .title3{
            margin-top: 5px;
            display: grid;
            grid-template-columns: minmax(0,1fr) minmax(0,1fr);
            line-height: 22px;
            font-size: 14px;
          }
          .back{
            margin-top: 15px;
            height: fit-content;
            background: #EFF5FF;
            border-radius: 9px;
            position: relative;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            //height:580px ;
            overflow:hidden;
            .imgDesign{
              width: 100%;
              height:195px;
              opacity: 1;
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
      .grid1{
        position: relative;
        height: fit-content;
        display: grid;
        margin-top: 25px;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr);
        column-gap: 2vw;
        row-gap: 15px;

        .card{
          border-radius: 9px;
          cursor: pointer;
          width: 100%;
          height:185px ;
          background-color: white;
          padding:5px 10px;
          opacity: 1;
          .title1{
            height: 45px;
            justify-content: center;
            align-items: center;
            display: flex;
            font-size: 16px;
            font-weight: bold;
          }
          .title2{
            margin: 0 5px;
            text-align: justify;
            line-height: 22px;
            font-size: 14px;
          }
          .titleDesign{
            margin-top: 10px;
          }
          .title3{
            margin-top: 5px;
            display: grid;
            grid-template-columns: minmax(0,1fr) minmax(0,1fr);
            line-height: 22px;
            font-size: 14px;
          }
          .back{
            margin-top: 15px;
            border-radius: 9px;
            position: relative;
            //height:580px ;
            overflow:hidden;
            .imgDesign{
              width: 40px;
              height:38px;
              margin:0 10px 0 5px;
              opacity: 1;
              background-position: center;
              background-size:contain;
              background-repeat: no-repeat;
              background-color: transparent;
            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
  }

}
</style>
