<template>
  <div>
    <div v-if="model===false">
      <div class="totalmakeHole" ref="total">
        <div class="topback"   :style="{ height: '6vh', backgroundColor: scrollback ? 'rgb(245 244 246)' : 'rgb(245 244 246)' ,position:'fixed',top:'0'}">
          <headtip :changeVisable="changeVisable"  @showDialog="showPartner"></headtip>
        </div>
        <div class="makeHole1" @click="changeheadVisable">
          <kefu @toptop="topTop"></kefu>
<!--          <div class="screen1">-->
<!--            &lt;!&ndash;       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台&ndash;&gt;-->
<!--            <div class="layer2">-->
<!--              <div v-sliden-instep="{duration:800}" class="title1" style="font-size: unset;line-height: unset"> <img loading="lazy" style="border-radius: 9px" src="@/assets/AnxunLogo.png"></div>-->
<!--              <div v-sliden-instep="{duration:1200}" class="title1" style="height: unset;line-height: unset;margin-top: 2vh;font-size: 2.4vw;color: #282d7d;font-weight: bolder">Process Big-data Analysis System Solutions</div>-->
<!--              <div v-sliden-instep="{duration:1600}"  class="title2" style="font-size: 1.3vw;margin-top: 3vh;font-weight: bolder">Designed for purification refineries with complex process flow,it realizes precise tuning of process parameters and processes, ensuring efficient and stable production operations-->
<!--              </div>-->
<!--              <div  v-sliden-instep="{duration:2000}"  class="title4" style="font-weight: bolder">Oil & Gas Digitalization, Simple and Practical</div>-->

<!--            </div>-->
<!--            <div v-zoom-in="{duration:800} "  style="position: absolute;bottom: 0px;font-weight: bolder"  class="titlle3"   @click="clickConsult('Oil & Gas Generic AI')">-->
<!--            Product Inquiry-->
<!--          </div>-->
<!--            &lt;!&ndash;      平台大图&ndash;&gt;-->
<!--            <div class="layer3"></div>-->
<!--          </div>-->
          <Anxunheader :title1="'Process Big-data Analysis System Solutions'" :title2="'Designed for purification refineries with complex process flow,it realizes precise tuning of process parameters and processes, ensuring efficient and stable production operations'"  @clickConsult="clickConsult('Oil & Gas Generic AI')"></Anxunheader>
<!--          <div class="screenmake0">-->
<!--            <div v-sliden-in="{duration:800}" class="heighLight">Process Big-data Analysis System Solutions</div>-->
<!--            <div v-sliden-in="{duration:800}" class="heightLight2">Process Big-data Analysis System Solutions are specifically designed for purification and refinery plants with complex processes. It deeply integrates big data, neural network algorithms, and artificial intelligence technology, achieving<span style=" color: #FF6400;"> precise optimization of process parameters, real-time alerts for equipment operation, effective management of energy conservation and consumption reduction, multi-dimensional comparative analysis, and scientific decision support</span>. It comprehensively assists enterprises in achieving efficient and stable production and operation.</div>-->
<!--          </div>-->

          <div class="screenmake0" style="padding-bottom: 0px">
<!--            <div v-sliden-in="{duration:800}" class="heighLight">Process Big-data Analysis System Solutions</div>-->
            <videoPlay :video-path="'https://www.oilgasgpts.com/static/media/%E8%8B%B1%E6%96%87-APDS-%E5%B7%A5%E8%89%BA%E5%A4%A7%E6%95%B0%E6%8D%AE%E5%88%86%E6%9E%90%E7%B3%BB%E7%BB%9F.mp4'"
                       :title1="'Process Big-data Analysis System Solutions'"
                       :title="'Process Big-data Analysis System Solutions are specifically designed for purification and refinery plants with complex processes. It deeply integrates big data, neural network algorithms, and artificial intelligence technology, achieving precise optimization of process parameters, real-time alerts for equipment operation, effective management of energy conservation and consumption reduction, multi-dimensional comparative analysis, and scientific decision support. It comprehensively assists enterprises in achieving efficient and stable production and operation.'">
            </videoPlay>
          </div>
          <div class="screenmake0" style="background-color: white">
            <div v-sliden-in="{duration:800}" class="heighLight">Your Benefits</div>
            <div v-sliden-in="{duration:800}" class="grid1" style="margin: auto">
<!--              <image-up-text-down :imagepath="require('@/assets/adv1.png')"></image-up-text-down>-->
              <div class="card">
                <div class="back">
                  <div style="display: flex;justify-content: center">
                    <div class="imgDesign">
                      <img loading="lazy"  style="border-radius: 9px" src="@/assets/technologyu01.png">
                    </div>
                    <div class="title1">
                      Efficiency Enhancement
                    </div>

                  </div>

                  <div class="titleDesign">
                    <div class="title2"> Adopt advanced technologies and optimize processes to improve client's efficiency, reduce costs, and accelerate business goals.             </div>
                  </div>
                </div>


              </div>
              <div class="card">
                <div class="back">
                  <div style="display: flex;justify-content: center">
                    <div class="imgDesign">
                      <img loading="lazy"  style="border-radius: 9px" src="@/assets/technologyu02.png">
                    </div>
                    <div class="title1">
                      Benefit Growth
                    </div>

                  </div>

                  <div class="titleDesign">
                    <div class="title2">
                      Assist clients in boosting revenue and profits through optimized resource allocation, efficiency enhancement, and market expansion.   </div>
                  </div>
                </div>


              </div>
<!--              <div class="card">-->
<!--                <div class="back">-->
<!--                  <div style="display: flex">-->
<!--                    <div class="imgDesign">-->
<!--                      <img loading="lazy"  style="border-radius: 9px" src="@/assets/technologyu03.png">-->
<!--                    </div>-->
<!--                    <div class="title1">-->
<!--                      Customer Experience-->
<!--                    </div>-->

<!--                  </div>-->
<!--                  <div class="titleDesign">-->
<!--                    <div class="title2">-->
<!--                      Focus on personalized services to meet client needs, enhancing satisfaction and loyalty.-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->


<!--              </div>-->
              <div class="card">
                <div class="back">
                  <div style="display: flex;justify-content: center">
                    <div class="imgDesign">
                      <img loading="lazy"  style="border-radius: 9px;" src="@/assets/technologyu04.png">
                    </div>
                    <div class="title1">
                      Reduce risk
                    </div>

                  </div>
                  <div class="titleDesign">
                    <div class="title2">
                      Introduce advanced risk management and safeguards to mitigate potential risks, ensuring stable business development.
                    </div>
                  </div>
                </div>


              </div>
            </div>

          </div>
          <div class="screenmake21" style="padding-top: 0">
            <div v-sliden-in="{duration:800}" class="heighLight"> Business Pain Points and Challenges</div>
<!--            <div class="line"></div>-->
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/technology01.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1"> Data Island and Information Sharing Challenges
                    </div>
                    <div class="title2">
                      Because it is inability to integrate and share data between different systems and devices, so result in data island.


                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:1200}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/technology02.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Varying Data Quality
                    </div>
                    <div class="title2">
                      Data generated during production may be missing, incorrect, or inconsistent, directly effecting accuracy and reliability of data analysis.


                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:1600}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/technology03.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Complexity and Uncertainty Process Procedures
                    </div>
                    <div class="title2">
                      Industrial production processes involve multiple stages and factors, with complex and variable process flows and uncertainties.

                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:2000}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/technology04.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Inadequate Decision Support
                    </div>
                    <div class="title2">
                      Due to insufficient data integration, lack analytical tools or analysis depth, it is impossible to provide adequate decision support for management.
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
          <div class="screenmake0" style="background-color: transparent">
            <div v-sliden-in="{duration:800}" class="heighLight" >
              Core Functions</div>
            <div class="grid">
              <div class="card">
                <div class="back" style="background-color: white">
                  <div v-left-in="{duration:800}" class="imgDesign">
                    <el-tooltip class="item" effect="dark" content="Click to enlarge for a clearer view!" placement="top">
                      <img loading="lazy" @click="showModalTrue(require('@/assets/img_4.png'))" style="border-top-left-radius: 9px;border-bottom-left-radius: 9px" src="@/assets/img_4.png">
                    </el-tooltip>
                  </div>
                  <div v-sliden-in="{duration:800}" class="titleDesign">
                    <div class="title2">
                      <li><span style="font-weight: bold">Data Auto-Collection</span><br>Automated collection, analysis, calculation, and statistics of real-time database, LIMS database, and electrical data.</li>
                      <li style="margin-top: 2vh"><span style="font-weight: bold">Report Configuration and Presentation</span><br>Dynamic configuration and export of production daily reports, technical monthly reports, energy consumption reports, and financial reports.</li>
                      <li style="margin-top: 2vh"><span style="font-weight: bold">Effect Evaluation</span><br> Automatic data synchronization, real-time analysis, and monitoring of production site equipment and materials.</li>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>

          <div class="screenmake4">
            <div v-sliden-in="{duration:800}" class="heighLight">Successful Cases</div>
            <div class="grid">
              <el-carousel arrow="always" indicator-position="outside">
                <el-carousel-item v-for="item in success" :key="item">
                  <div class="card">
                    <div class="back">
                      <div v-left-in="{duration:800}" class="imgDesign">
                        <el-tooltip class="item" effect="dark" content="Click to enlarge for a clearer view!" placement="top">
                          <img loading="lazy" @click="showModalTrue(item.img)" :src="item.img"/>

                        </el-tooltip>
                      </div>
                      <div v-sliden-in="{duration:800}" class="titleDesign">
                        <div class="title1">{{ item.title1 }}</div>
                        <div class="title2" v-html="item.title2"></div>
                      </div>
                    </div>


                  </div>


                </el-carousel-item>
              </el-carousel>
            </div>

          </div>
          <bottomTip></bottomTip>
        </div>
        <div v-if="showModal" @click="closeModal" class="modal-overlay">
          <div class="modal-content">
            <img :src="largeSrc" alt="Large Image" class="large-image">
          </div>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
    <div v-else>
      <div class="totalmakeHoleModel">
        <div class="makeHole1">
          <div class="screen1">
            <headtip @showDialog="showPartner"></headtip>
            <kefuMobile @topMobile="topTopmobile"></kefuMobile>
            <!--       油气通GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div v-zoom-in="{duration:800}" class="title1"> <img loading="lazy" style="border-radius: 9px" src="@/assets/AnxunLogo.png"></div>
              <div v-zoom-in="{duration:800}" class="title2" style="font-weight: bolder;font-size:30px;margin-top: 15px;color:black;line-height: 28px">Process Big-data Analysis System</div>
              <div v-zoom-in="{duration:800}" class="title2" style="font-weight: bolder;font-size:20px;line-height:24px;margin-top:15px;color:black">Designed for purification refineries with complex process flow,it realizes precise tuning of process parameters and processes, ensuring efficient and stable production operations
              </div>
            </div>
            <div  class="title4" style="font-weight:bolder">Oil & Gas Digitalization, Simple and Practical</div>
            <div class="titlle3" @click="clickConsult('Oil & Gas Generic AI')" style="font-weight: bolder">
              Product Inquiry
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>
<!--          <div class="screenmake0">-->
<!--            <div v-sliden-in="{duration:800}" class="heighLight">Process Big-data Analysis System Solutions</div>-->
<!--            <div v-sliden-in="{duration:800}" class="heightLight2">Process Big-data Analysis System Solutions are specifically designed for purification and refinery plants with complex processes. It deeply integrates big data, neural network algorithms, and artificial intelligence technology, achieving<span style=" color: #FF6400;"> precise optimization of process parameters, real-time alerts for equipment operation, effective management of energy conservation and consumption reduction, multi-dimensional comparative analysis, and scientific decision support</span>. It comprehensively assists enterprises in achieving efficient and stable production and operation.</div>-->
<!--          </div>-->
          <div class="screenmake0">
            <div v-sliden-in="{duration:800}" class="heighLight">Process Big-data Analysis System Solutions</div>
          <video-play-mobile :video-path="'https://www.oilgasgpts.com/static/media/%E8%8B%B1%E6%96%87-APDS-%E5%B7%A5%E8%89%BA%E5%A4%A7%E6%95%B0%E6%8D%AE%E5%88%86%E6%9E%90%E7%B3%BB%E7%BB%9F.mp4'"
                             :title="'Process Big-data Analysis System Solutions are specifically designed for purification and refinery plants with complex processes. It deeply integrates big data, neural network algorithms, and artificial intelligence technology, achieving precise optimization of process parameters, real-time alerts for equipment operation, effective management of energy conservation and consumption reduction, multi-dimensional comparative analysis, and scientific decision support. It comprehensively assists enterprises in achieving efficient and stable production and operation.'">
          </video-play-mobile>
          </div>
          <div class="screenmake0" style="background-color: white">
            <div class="heighLight">Your Benefits</div>
            <div class="grid1">
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div style="display: flex">
                    <div class="imgDesign">
                      <img loading="lazy"  style="border-radius: 9px;;height: 33px;margin-top: 5px" src="@/assets/technologyu01.png">
                    </div>
                    <div class="title1">
                      Efficiency Enhancement
                    </div>

                  </div>

                  <div class="titleDesign">
                    <div class="title2"> Adopt advanced technologies and optimize processes to improve client's efficiency, reduce costs, and accelerate business goals.             </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div style="display: flex">
                    <div class="imgDesign">
                      <img loading="lazy"  style="border-radius: 9px;;height: 39px;margin-top: 5px" src="@/assets/technologyu02.png">
                    </div>
                    <div class="title1">
                      Benefit Growth
                    </div>
                  </div>
                  <div class="titleDesign">
                    <div class="title2">
                      Assist clients in boosting revenue and profits through optimized resource allocation, efficiency enhancement, and market expansion.   </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back" style="height: 180px;margin-top: 0">
                  <div style="display: flex">
                    <div class="imgDesign">
                      <img loading="lazy"  style="border-radius: 9px;height: 30px;margin-top: 5px" src="@/assets/technologyu03.png">
                    </div>
                    <div class="title1">
                      Customer Experience
                    </div>

                  </div>
                  <div class="titleDesign">
                    <div class="title2">
                      Focus on personalized services to meet client needs, enhancing satisfaction and loyalty.
                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back" style="height: 180px;margin-top: 0">
                  <div style="display: flex">
                    <div class="imgDesign">
                      <img loading="lazy"  style="border-radius: 9px;height: 34px;margin-top: 3px" src="@/assets/technologyu04.png">
                    </div>
                    <div class="title1">
                      Risk Reduction
                    </div>

                  </div>
                  <div class="titleDesign">
                    <div class="title2">
                      Introduce advanced risk management and safeguards to mitigate potential risks, ensuring stable business development.
                    </div>
                  </div>
                </div>


              </div>
            </div>

          </div>
          <div class="screenmake21" style="padding-top: 20px;margin-top: unset">
            <div v-sliden-in="{duration:800}" class="heighLight"> Business Pain Points and Challenges</div>
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card" style="margin-top: 10px">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/technology01.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1"> Data Island and Information Sharing Challenges
                    </div>
                    <div class="title2">
                      Because it is inability to integrate and share data between different systems and devices, so result in data island.


                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/technology02.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Varying Data Quality
                    </div>
                    <div class="title2">
                      Data generated during production may be missing, incorrect, or inconsistent, directly effecting accuracy and reliability of data analysis.


                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/technology03.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Complexity and Uncertainty Process Procedures
                    </div>
                    <div class="title2">
                      Industrial production processes involve multiple stages and factors, with complex and variable process flows and uncertainties.

                    </div>
                  </div>
                </div>


              </div>
              <div v-sliden-in="{duration:800}" class="card">
                <div class="back">
                  <div class="imgDesign">
                    <img loading="lazy" src="@/assets/technology04.png" style="border-radius: 9px">
                  </div>
                  <div class="titleDesign">
                    <div class="title1">Inadequate Decision Support
                    </div>
                    <div class="title2">
                      Due to insufficient data integration, lack analytical tools or analysis depth, it is impossible to provide adequate decision support for management.

                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
          <div class="screenmake0" style="background-color: transparent">
            <div v-sliden-in="{duration:800}" class="heighLight" >
              Core Functions</div>
            <div class="grid">
              <div v-sliden-in="{duration:800}" class="card" >
                <div class="back" style="background-color: transparent">
                  <div class="imgDesign">
                    <img loading="lazy"  style="border-top-left-radius: 9px;border-bottom-left-radius: 9px" src="@/assets/img_4.png">
                  </div>
                  <div class="titleDesign">
                    <div class="title2">
                      <li><span style="font-weight: bold">Data Auto-Collection</span><br>Automated collection, analysis, calculation, and statistics of real-time database, LIMS database, and electrical data.</li>
                      <li><span style="font-weight: bold">Report Configuration and Presentation</span><br>Dynamic configuration and export of production daily reports, technical monthly reports, energy consumption reports, and financial reports.</li>
                      <li><span style="font-weight: bold">Effect Evaluation</span><br> Automatic data synchronization, real-time analysis, and monitoring of production site equipment and materials.</li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="screenmake4">
            <div v-sliden-in="{duration:800}" class="heighLight">Successful Cases</div>
            <div class="grid">
              <el-carousel arrow="always" indicator-position="outside">
                <el-carousel-item v-for="item in success" :key="item">
                  <div class="card">
                    <div class="back">
                      <div v-zoom-in="{duration:800}" class="imgDesign">
                        <img :src="item.img"/>
                      </div>
                      <div v-zoom-in="{duration:800}" class="titleDesign">
                        <div class="title1">{{ item.title1 }}</div>
                        <div class="title2" v-html="item.title2"></div>
                      </div>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
          <div ref="bottom">
            <bottomTip></bottomTip>
          </div>
        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
  </div>
</template>
<script>
import headtip from "@/views/headTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import {shareUrl} from "@/utils/vxshare";
import kefu from "@/views/kefu.vue";
import kefuMobile from "@/views/kefuMobile.vue";
import {vSlidenIn} from "@/utils/vSlidenIn.js";
import {vZoomIn} from "@/utils/vZoomIn.js";
import {vLeftIn} from "@/utils/vLeftIn.js";
import {vSlidenInstep} from "@/utils/vSlidenInstep";
import Anxunheader from "@/views/Anxun/component/Anxunheader.vue";
import videoPlay from "@/components/videoPlay.vue";
import videoPlayMobile from "@/components/videoPlayMobile.vue";
// import ImageUpTextDown from "@/views/component/imageUpTextDown.vue";
export default {
  directives: {
    slidenIn: vSlidenIn,
    zoomIn: vZoomIn,
    leftIn:vLeftIn,
    slidenInstep:vSlidenInstep
  },
  name: "",

  props: [],

  components: {videoPlayMobile, videoPlay, Anxunheader, PartnerTip, consultTip, headtip, bottomTip,kefu,kefuMobile},

  data() {

    return {
      changeVisable:1,

      showModal: false,
      largeSrc:'',
      title: 'Oil & Gas Generic AI',
      pageTitle: 'Process Big-data Analysis System  Solution_Anvision-Oil & Gas Communication GPT',
      pageDescription: 'Anvision Digital Smart Field technology Process Big-data Analysis System , a Process Big-data Analysis System  with real-time data and LIMS data as the core, can improve profitability, maximize benefits, and enhance the core competitiveness of enterprises for the production and construction of Oil & Gas technologys.',
      pageKeywords: 'Site process management, Process Big-data Analysis System , site Process Big-data Analysis System , Anvision, Oil & Gas Communication GPT',
      model: false,
      scrollback:false,
      dialogconsult: false,
      dialogVisible: false,
      success: [{
        img: require("@/assets/anxun52.png"),
        title1: "Process Big-data Analysis System ",
        title2: "<li>Process Big-data Analysis System  of a Purification Plant in Guangyuan</li><li>Process Big-data Analysis System  of a Purification Plant in Dazhou</li>",
      }, {
        img: require("@/assets/anxun53.png"),
        title1: "Process Big-data Analysis System ",
        title2: "<li>Process Big-data Analysis System  of a Purification Plant in Guangyuan</li><li>Process Big-data Analysis System  of a Purification Plant in Dazhou</li>",

      },
      ]
    }

  },

  methods: {
    topTopmobile() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    topTop() {
      console.log("fuzujianchuanzhi")
      //const element = this.$refs.total;
      //const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      const element = this.$refs.total;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.$nextTick(() => {
        this.$refs.total.scrollTop = rect
      });
    },

    showModalTrue(item){
      this.showModal = true;
      console.log(item)
      this.largeSrc=item
    },
    closeModal() {
      this.showModal = false;
    },
    clickConsult(item) {

      console.log(item)
      this.title = item
      this.dialogVisible = true;
      sessionStorage.setItem('title', item)
    },
    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
    hide() {
      this.dialogconsult = false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    showPartner() {
      this.dialogconsult = true;
    },
    changeheadVisable(){
      if(this.changeVisable===1){
        this.changeVisable=0
      }
      else{
        this.changeVisable=1
      }
      console.log("父组件", this.changeVisable)
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    handleScroll() {
      if (this.model === false) {
        this.scrollHeight = this.$refs.total.scrollTop;
      } else {
        this.scrollHeight = this.$refs.totalModel.scrollTop;
      }

      if (this.scrollHeight < 100) {
        this.scrollTrue = true
      } else {
        this.scrollTrue = false
      }
      if (this.scrollHeight < 50) {
        this.scrollback = false
      } else {
        this.scrollback = true
      }
      console.log("gaodu", this.scrollHeight < 100)
    },
  },
  //进入该页面时，用之前保存的滚动位置赋值
  beforeDestroy() {
    // 移除事件监听器
    this.$refs.total.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.$refs.total.addEventListener('scroll', this.handleScroll);

    document.title = this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if (screenWidth <= 700) {
      this.model = true
    } else {
      this.model = false
    }

    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  created() {
    localStorage.setItem("home", 0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: 'Process Big-data Analysis System  solution_Anvision-Oil & Gas Communication GPT',
        pageDescription: 'Anvision Digital Smart Field technology Process Big-data Analysis System , a Process Big-data Analysis System  with real-time data and LIMS data as the core, can improve profitability, maximize benefits, and enhance the core competitiveness of enterprises for the production and construction of Oil & Gas technologys.',
        pageKeywords: 'Site process management, Process Big-data Analysis System , site Process Big-data Analysis System , Anvision, Oil & Gas Communication GPT',

      };
      shareUrl(shareData);
    }, 1000);
  },

}

</script>


<style lang="scss" scoped type="text/scss">
.totalmakeHole {
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .topback{
    position: fixed;
    z-index:999999999999;
    width: 100vw;
    padding: 0 0 0 2.86077411900635vw;
  }
  .makeHole1 {
    //z-index:: 3;
    position: relative;
    //width: 100%;
    //height: 100vh;

    .screen1 {
      //z-index:: 3;
      position: relative;
      width: 100%;
      height: 74vh;
      padding: 7.8430160692vh 2.860774119vw 2.8430160692vh 5.860774119vw;
      background-image: url("../../assets/banner.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */
      .titlle3{
        //margin-top: 2.07911001236094vh;
        margin:6vh;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1vw;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #ffffff;
        line-height: 3.3374536465vh;
        text-align: center;
        border-radius: 9.1px;
        width: fit-content;
        padding: 2.4624227441vh 1.1989601386vw;
        height: 4.5624227441vh;
        background: #2f6eff;
        border: #2f6eff 1px solid;

      }
      .titlle3:hover{

        color: #2168DB;
        border: #2168DB 1px solid;
        background: rgba(255, 255, 255, 0.5);
      }
      .layer2 {
        position: absolute;
        top: 16.1495673671199vh;
        left: 8.17157712305026vw;

        .title1 {
          width: fit-content;
          height: 5.77626699629172vh;
          font-size: 3.50606585788562vw;
          font-family:"Times New Roman";
          font-weight: 400;
          color: #2f6eff;
          line-height: 12.23733003708282vh;
        }

        .title2 {
          margin-top: 4.82076637824475vh;
          width: 37vw;
          //text-align: justify;
          height: fit-content;
          font-size: 0.9597920277vw;
          font-family:"Times New Roman";
          font-weight: 400;
          color: black;
          line-height: 3.51520395550062vh;
        }

        .title4 {
          height: 8.77626699629172vh;
          font-size: 1.3060658579vw;
          font-family:"Times New Roman";
          font-weight: 400;
          color: #2f6eff;
          line-height: 9.31520395550062vh;
        }

        .titlle3 {
          margin-top: 2.07911001236094vh;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1vw;
          font-family:"Times New Roman";
          font-weight: 400;
          color: #ffffff;
          line-height: 3.3374536465vh;
          text-align: center;
          border-radius: 9.1px;
          width: fit-content;
          padding: 2.4624227441vh 1.1989601386vw;
          height: 4.5624227441vh;
          background: #2f6eff;
          border: #2f6eff 1px solid;

        }

        .titlle3:hover {

          color: #2168DB;
          border: #2168DB 1px solid;
          background: rgba(255, 255, 255, 0.5);
        }
      }

      //.layer3 {
      //  position: absolute;
      //  top: 5.06798516687268vh;
      //  right: 11.61178509532062vw;
      //  width: 47.42923165800116vw;
      //  height: 72.80593325092707vh;
      //  background-image: url("../assets/GPTplant.png");
      //  background-size: 100% auto;
      //  background-position: center;
      //  background-repeat: no-repeat;
      //  background-color: transparent;
      //  opacity: 1;
      //}
    }
    .screenmake21 {
      padding: 7.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;
      position: relative;
      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:6.23733003708282vh;
        opacity: 1;
        font-size: 1.7vw;
        font-weight: 600;
        //color: #53A3F7;
        font-family: 'Times New Roman';
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: left;
        height: fit-content;
        font-weight: bold;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 80vw;
        font-size: 1.15vw;
        font-family: 'Times New Roman';
      }
      .line{
        height: 1vh;
        width: 82%;
        left:50%;
        transform: translateX(-50%);
        border-bottom: dashed black 1px;
        position: absolute;
        top:11vh;
        z-index: 1;

      }

      .grid {
        z-index: 2;
        margin-top: 0vh;
        position: relative;
        display: grid;
        grid-template-columns:  minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 1vw;

        .card {
          //cursor: pointer;
          width: 100%;
          height: fit-content;
          padding: 0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back {
            //background: #EFF5FF;
            border-radius: 9px;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height: fit-content;
            overflow: hidden;

            .imgDesign {
              width: 8vh;
              height:8vh;
              margin:0 auto 2vh auto;
              opacity: 1;
            }

            .titleDesign {
              padding: 0;
              margin: 0 auto;
              color: black;
              float: right;

              .title1 {
                margin-top: 0;
                text-align: center;
                font-size: 1.1709416522vw;
                font-weight: bold;
                height: 5vh;
                display: flex;
                justify-content: center;
                align-items: center;
              }

              .title2 {
                margin-top: 2vh;
                text-align: left;
                line-height: 3.194066749vh;
                font-size: 0.9vw;
              }

              .title3 {
                display: grid;
                grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 65.87268232385661vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake0{
      padding: 7.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;
      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:6.23733003708282vh;
        opacity: 1;
        font-size: 1.7vw;
        font-weight: 600;
        //color: #53A3F7;
        font-family: 'Times New Roman';
        line-height: 4.23733003708282vh;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: left;
        height: fit-content;
        font-weight: bold;
        color: #2C3E50;
        line-height: 4.94437577255871vh;

        width: 80vw;
        font-size: 1.15vw;
        font-family: 'Times New Roman';
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            background: #eeeeef;
            border-radius: 9px;
            display: flex;
            position: relative;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            height:50vh ;
            overflow:hidden;
            .imgDesign{
              width: fit-content;
              height:100%;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .titleDesign{
              padding: 0 1.5vw 0 1.5vw;
              margin:auto 1vw auto auto;
              color: black;
              width:40%;
              float: right;
              .title1{
                margin-top: 2vh;
                font-size: 1vw;
                font-weight: bold;
              }
              .title2{
                line-height: 2.8vh;
                font-size: 0.8243212016vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
      .grid1{
        margin-top:2.78739184177998vh ;
        position: relative;
        display: grid;
        width: 80%;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            background: #ebf1fd;
            border-radius: 9px;
            position: relative;
            box-shadow: 2px 0 12px 0px #c9d9f5;
            height:32vh ;
            overflow:hidden;
            .imgDesign{

              width: fit-content;
              height:6vh;
              opacity: 1;
              margin:3vh 1vw 2vh 1.5vw;
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }
            .title1{
              margin-top: 4vh;
              font-size: 1.1709416522vw;
              font-weight: bold;
            }
            .titleDesign{
              padding: 0 1.5vw 2.5248454883vh 1.5vw;
              margin:0 auto;
              color: black;
              width:100%;
              float: right;
              .title1{
                margin-top: 4vh;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }
              .title2{
                line-height: 2.8vh;
                font-size: 0.9vw;
              }

            }

          }
        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }


    .screenmake4 {
      padding: 7.78739184177998vh 5.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: #212a3c;

      .heighLight {
        display: flex;
        justify-content: center;
        text-align: center;
        height: 8.23733003708282vh;
        font-size: 1.7vw;
        font-family:"Times New Roman";
        font-weight: 400;
        color: white;
        line-height: 4.23733003708282vh;

      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .card {
          //cursor: pointer;
          width: 86%;
          margin: 0 auto;
          height: fit-content;
          padding: 0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;

          .back {
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            height: 52vh;
            overflow: hidden;
            background: #19202e;

            .imgDesign {
              width: fit-content;
              height: 100%;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 1.5248454883vh 1.5248454883vw 1.5248454883vh 1vw;
              margin: auto ;
              color: white;
              width: 35%;
              float: right;

              .title1 {
                font-size: 1vw;
              }

              .title2 {
                margin-top: 3vh;
                line-height: 4vh;
                font-size: 0.8243212016vw;

                ::v-deep li {
                  margin-top: 5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 54vh !important;
        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }

        ::v-deep .el-carousel__arrow {
          outline: 10vh;
          padding: 0;
          margin: 0;
          //cursor: pointer;

          border-radius: 50%;
          width: 55px;
          height: 55px;
          background-color: rgba(249, 250, 252, 0.2);
          border: rgba(255, 255, 255, 0.5) 1px solid;
          color: #fff;
          position: absolute;
          top: 50%;
          z-index: 100;
          transform: translateY(-50%);
          text-align: center;
          font-weight: 800;
          font-size: 25px;
        }
      }
    }
  }
  .modal-overlay {
    z-index: 999999999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  }

  .large-image {
    max-width: 100%;
    max-height: 80vh;
  }
}

.totalmakeHoleModel {
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;

  .makeHole1 {
    //z-index:: 3;
    position: relative;
    width: 100%;
    //height: 100vh;

    .screen1 {
      //z-index:: 3;
      position: relative;
      width: 100%;
      height: 670px;
      padding: 2.84301606922126vh 2.86077411900635vw 2.84301606922126vh 7.86077411900635vw;
      background-image: url("../../assets/bannerModel.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

      .layer2 {
        position: absolute;
        top: 80px;
        left: 9.17157712305026vw;

        .title1 {
          width: 120px;
          font-size: 3.50606585788562vw;
          font-family:"Times New Roman";
          font-weight: 400;
          color: #2f6eff;
        }

        .title2 {
          margin-top: 10px;
          width: 95%;
          height: fit-content;
          font-size: 14px;
          font-family:"Times New Roman";
          font-weight: 400;
          color: #4B5A8B;
          line-height: 20px;
        }


      }

      .title4 {
        position: absolute;
        bottom: 100px;
        left: 50%;
        transform: translateX(-50%);
        height: fit-content;
        font-weight: 400;
        width: 100%;
        font-size: 16px;
        color: #2f6dfd;
        line-height: 20px;
        text-align: center; /* 用于内部文本的水平居中 */
      }

      .titlle3 {
        position: absolute;
        bottom: 30px;
        left: 50%;
        transform: translateX(-60%);
        font-size: 14px;
        font-family:"Times New Roman";
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 9.1px;
        width: fit-content;
        margin: 0 15px;
        padding: 5px 4.6875vw;
        height: 35px;
        color: white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;

      }
    }
    .screenmake21{
      width: 100vw;
      height: fit-content;
      margin-top: -15px;
      padding:0  4.6875vw 20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: 'Times New Roman';
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: 'Times New Roman';
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        position: relative;
        height: fit-content;
        display: grid;
        margin-top: 10px;
        grid-template-columns: minmax(0,1fr) ;
        column-gap: 5px;
        .card{
          cursor: pointer;
          width: 100%;
          height:fit-content ;
          display: flex;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .title1{
            margin: 5px auto;
            text-align: left;
            font-size: 16px;
            font-weight: bold;
          }
          .title2{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .title3{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .titleDesign{
            width: 90%;
            margin: 0 0 0 15px;
          }
          .back{
            background: white;
            border-radius: 9px;
            column-gap: 5px;
            position: relative;
            padding: 0 10px 0 10px;
            height:fit-content;
            display: flex;
            overflow:hidden;
            .imgDesign{
              width: 45px;
              height:45px;
              margin:0 auto 15px auto;
              opacity: 1;
            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake0{
      //z-index:: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size: 21px;
        //color: #53A3F7;
        font-family: 'Times New Roman';
        font-weight: 700;
        line-height: 26px;
      }
      .heightLight2{
        margin:19px 2vw;
        //text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: 'Times New Roman';
        font-weight: bold;
        color: #2C3E50;
        line-height: 25px;
      }

      .grid{
        position: relative;
        height: fit-content;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .title1{
            margin-top: 5px;
            font-size: 16px;
            font-weight: bold;
          }
          .title2{
            margin-top: 5px;

            line-height: 22px;
            font-size: 14px;
          }
          .titleDesign{
            margin-top: 20px;
          }
          .title3{
            margin-top: 5px;
            display: grid;
            grid-template-columns: minmax(0,1fr);
            line-height: 22px;
            font-size: 14px;
          }
          .back{
            margin-top: 15px;
            background: white;
            border-radius: 9px;
            position: relative;
            //height:580px ;
            overflow:hidden;
            .imgDesign{
              width: 100%;
              height:100%;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
      .grid1{
        position: relative;
        height: fit-content;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,1fr);
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;
          .title1{
            margin-top: 5px;
            font-size: 16px;
            font-weight: bold;
          }
          .title2{
            margin-top: 5px;
            line-height: 22px;
            font-size: 14px;
          }
          .titleDesign{
            margin-top: 10px;
          }
          .back{
            margin-top: 15px;
            background: white;
            padding: 10px 10px;
            border-radius: 9px;
            position: relative;
            height:200px ;
            overflow:hidden;
            .imgDesign{
              width: 55px;
              margin: 0 10px 0 auto;
              height:fit-content;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size:cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }


          }

        }

        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }

    .screenmake4 {
      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;

      padding: 20px 4.6875vw;
      background: black;

      .heighLight {
        text-align: center;
        height: 21px;
        opacity: 1;
        font-size: 21px;
        color: white;
        font-family:"Times New Roman";
        font-weight: 700;
        line-height: 26px;
      }

      .heightLight2 {
        margin: 19px 2vw;
        //text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size: 14px;
        font-family:"Times New Roman";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid {
        margin-top: 2.78739184177998vh;
        position: relative;

        .arrowButtonleft {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          //cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          left: -55px;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }

        .arrowButtonright {
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          background: white;
          color: black;
          position: absolute;
          top: 50%;
          right: -55px;
          //z-index:: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 1.2432120161756vw;
          font-weight: bold;
        }

        .card {
          cursor: pointer;
          width: 98%;
          margin: 0 auto;
          min-height: 250px;
          padding: 0 0.69324090121317vw 0 0;
          opacity: 1;
          background: #19202e;

          .back {
            border-radius: 9px;
            position: relative;
            //box-shadow: 5px 0 14px 0px #D4E4FF;
            min-height: 280px;
            overflow: hidden;
            background: #19202e;

            .imgDesign {
              width: 100%;
              height: 193px;
              opacity: 1;
              //background-image: url("../../assets/menage6.png");
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-color: transparent;
            }

            .titleDesign {
              padding: 15px 20px 25px 20px;
              color: white;
              width: 100%;
              float: right;

              .title1 {
                margin-top: 15px;
                font-size: 16px;
              }

              .title2 {
                margin-top: 15px;
                line-height: 22px;
                font-size: 14px;

                ::v-deep li {
                  margin-top: 5px;
                }
              ;
              }


            }

          }
        }

        ::v-deep .el-carousel__container {
          height: 380px !important;

        }

        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }

        ::v-deep .el-carousel__arrow {
          display: block;
          //border: 1px solid white;
          outline: -5px;
          padding: 0;
          margin: -78px -10px;
          cursor: pointer;

          border-radius: 0;
          width: 25px;
          height: 48px;
          background: rgba(245, 246, 250, 0.8);
          color: #131313;
          position: absolute;
          top: 50%;
          //z-index:: 100;
          transform: translateY(-50%);
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }

}
</style>
